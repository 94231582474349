import { union, snakeCase, filter, omit, isEqual, cloneDeep } from 'lodash-es'
import Cookies from 'js-cookie'
import qs from 'qs'

export function errorKeys (code, options) {
  if (code) code += ''
  options = options || {}
  const prefix = options.prefix != null ? options.prefix : ''
  let suffix = options.suffix != null ? options.suffix : ''
  const separator = options.separator != null ? options.separator : '-'
  suffix = Array.isArray(suffix) ? suffix : [suffix]
  const pageKey = typeof code === 'string' && code.split(separator)

  const keys = []
  for (let i = pageKey.length; i > 0; i--) {
    suffix.forEach((msuffix) => {
      keys.push(`${prefix}${pageKey.slice(0, i).join(separator)}${msuffix}`.toLowerCase())
    })
  }

  return keys
}

export function trim (str) {
  return str != null && typeof str === 'string' ? str.trim() : str
}

export function isEmpty (v) {
  return v == null || trim(v) === ''
}

export function trimToNull (v) {
  return isEmpty(v) ? null : trim(v)
}

export function isIE () {
  const ua = window.navigator.userAgent
  // IE 10 or older
  const msie = ua.indexOf('MSIE ')
  // IE 11
  const trident = ua.indexOf('Trident/')
  return (msie > 0 || trident > 0)
}

export function saveAs (data, type, filename) {
  const blob = new window.Blob([data], { type })
  if (isIE()) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = filename
    a.href = url
    a.textContent = 'Download ' + filename
    document.body.appendChild(a)
    a.click()
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 100)
  }
}

export function invalidChars (text, chars) {
  if (text == null || text.length === 0) return null
  const usedChars = union(text.split('')).join('').split('')
  const badChars = filter(usedChars, (c) => chars.indexOf(c) === -1)
  if (badChars.length > 0) return badChars.join('')
  return null
}

export function maptoLocale (lang) {
  const localeMap = {
    en: 'en_GB',
    fi: 'fi_FI',
    de: 'de_DE',
    da: 'da_DK',
    sv: 'sv_SE',
    nl: 'nl_NL',
    fr: 'fr_FR',
    it: 'it_IT',
    es: 'es_ES',
    nb: 'nb_NO'
  }
  if (lang == null) return lang
  if (lang.length === 5) return lang
  return localeMap[lang] || lang.toLowerCase() + '_' + lang.toUpperCase()
}

export function getUTM () {
  const query = qs.parse(window.location.search.substr(1))
  return [
    { c: 'referrer-domain', t: 'referrerDomain' },
    { c: 'utm-campaign', t: 'utmCampaign' },
    { c: 'utm-content', t: 'utmContent' },
    { c: 'utm-keyword', t: 'utmKeyword' },
    { c: 'utm-medium', t: 'utmMedium' },
    { c: 'utm-source', t: 'utmSource' },
    { c: 'var', t: 'var' }
  ].reduce(function (memo, cookie) {
    const { c: key } = cookie
    const keySnake = snakeCase(key)
    const v = query != null && query[keySnake] != null ? query[keySnake] : Cookies.get(key)
    if (v && v !== 'undefined') memo[cookie.t] = v
    return memo
  }, {})
}

export function cleanObject (obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Converts falsy values in an object to null.
 * @example
 * value.title = '' => value.title = null
 * **/
export function convertFalsyValuesToNullInObject (value) {
  const data = cloneDeep(value)
  Object.keys(data).forEach(prop => {
    if (isEmpty(data[prop])) {
      data[prop] = null
    }
    if (prop === 'description' && data[prop] === '<p></p>') {
      data[prop] = null
    }
  })
  return data
}

/**
 * Check if a form was edited. Be careful this method doesn't check for identity!
 * This method is mainly used (atm) for the route guards to check if a form was edited.
 * For this reason some properties are omitted, because the user can't edit them anyway.
 * **/
export function formWasEdited (source, other) {
  const preparedSource = prepareObject(omit(source, ['uid']))
  const preparedOther = prepareObject(omit(other, ['uid']))

  //edge case with imagePlaceholder because this value gets set in the created()...
  if (preparedSource.assets.filter(({ type }) => type === 'main').length > 0) preparedSource.imagePlaceholder = false
  if (preparedOther.assets.filter(({ type }) => type === 'main').length > 0) preparedOther.imagePlaceholder = false

  function prepareObject (data) {
    data.chargingFeeSelector.chargingFees.forEach((fee) => {
      delete fee._condition
    })

    data.ticketAreas.forEach((area) => {
      delete area.uid
      delete area.reference
      area.ticketTypes.forEach((type) => {
        delete type.uid
        delete type.reference
      })
    })

    return convertFalsyValuesToNullInObject(data)
  }

  return !isEqual(cleanObject(preparedSource), cleanObject(preparedOther))
}
