<template>
  <v-app v-if="!ready">
    <not-available v-if="unavailable" />
    <loading v-else />
  </v-app>
  <v-app v-else>
    <v-app-bar :color="navbar.color" :dark="navbar.dark" clipped-right class="elevation-0 flex-grow-0" :style="{ zIndex: 2 }">
      <v-app-bar-nav-icon v-if="showDrawer" @click="drawer = !drawer" />
      <router-link v-if="isAuthenticated" class="no-select" to="/" style="min-width:100px;">
        <img :src="`${assetBasePath}img/logos/${config.logo}`" class="mr-2 mt-2" style="max-height:60px;width:100%">
      </router-link>
      <a v-else :href="marketingUrl" class="no-select" style="min-width:100px;">
        <img :src="`${assetBasePath}img/logos/${config.logo}`" class="mr-2 mt-2" style="max-height:60px;width:100%">
      </a>

      <template v-if="isAuthenticated && !showDrawer">
        <v-sheet min-width="0" :color="navbar.color" :dark="navbar.dark">
          <v-slide-group show-arrows>
            <v-slide-item v-for="item in userItems" :key="item.text">
              <v-btn
                depressed
                rounded
                class="mx-1"
                :color="navbar.color"
                :dark="navbar.dark"
                :to="item.to"
                :href="item.href"
                :target="item.target"
                :small="$vuetify.breakpoint.mdAndDown"
                @click="item.click != null ? item.click() : () => {}"
              >
                <v-icon v-if="item.icon" class="mr-2">
                  {{ item.icon }}
                </v-icon> {{ item.text }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </template>

      <v-spacer />

      <template v-if="!isAuthenticated && !showDrawer">
        <v-btn
          v-for="item in guestItems"
          :key="item.text"
          depressed
          rounded
          :color="navbar.color"
          :dark="navbar.dark"
          class="mx-1"
          :to="item.to"
          @click="item.click != null ? item.click() : () => {}"
        >
          <v-icon v-if="item.icon" class="mr-2">
            {{ item.icon }}
          </v-icon> {{ item.text }}
        </v-btn>
      </template>

      <v-btn
        v-if="$feature('hasMarketing') && config.marketing.help && isAuthenticated && !showDrawer"
        depressed
        rounded
        :color="navbar.color"
        :dark="navbar.dark"
        class="mx-1"
        to="/help"
      >
        {{ $t('button.help') }}
      </v-btn>
      <v-menu v-if="isAuthenticated && !showDrawer" v-model="accountMenu" close-on-click close-on-content-click offset-y>
        <template #activator="{ on }">
          <v-btn
            rounded
            depressed
            :color="navbar.color"
            :dark="navbar.dark"
            class="mr-1 pr-1"
            :small="$vuetify.breakpoint.mdAndDown"
            v-on="on"
          >
            {{ currentName }}
            <v-icon :class="!$vuetify.breakpoint.mdAndDown ? 'ml-2' : ''">
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in accountItems"
            :key="index"
            :to="item.to"
            :href="item.href"
            :target="item.target"
            @click="item.click != null ? item.click() : () => {}"
          >
            <v-list-item-title class="font-weight-medium">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-if="showDrawer" v-model="drawer" app clipped color="grey lighten-4">
      <v-app-bar v-if="isAuthenticated" :color="navbar.color" :dark="navbar.dark" class="elevation-0 flex-grow-0">
        <v-icon class="mr-2">
          mdi-account-circle
        </v-icon>
        {{ currentName }}
        <div class="flex-grow-1" />
        <v-spacer />
        <v-menu v-if="isAuthenticated" v-model="accountMenu" close-on-click close-on-content-click offset-y>
          <template #activator="{ on }">
            <v-btn class="mr-1 pr-1" icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in accountItems"
              :key="index"
              :to="item.to"
              :href="item.href"
              :target="item.target"
              @click="item.click != null ? item.click() : () => {}"
            >
              <v-list-item-title class="font-weight-medium">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-list class="grey lighten-4">
        <template v-for="(item, i) in isAuthenticated ? userItems : guestItems">
          <v-row v-if="item.heading" :key="i" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn small text>
                edit
              </v-btn>
            </v-col>
          </v-row>

          <v-divider v-else-if="item.divider" :key="i" dark class="my-4" />

          <v-list-item v-else :key="i" :to="item.to" :href="item.href">
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-alert v-if="$feature('behalfOf') && behalfOf" tile dismissible class="mb-0 red py-2 body-2" dark>
        <template #close>
          <v-icon @click="stopBehalfOf">
            mdi-close
          </v-icon>
        </template>
        {{ $t('behalf-of.notice', behalfOf) }}
      </v-alert>
      <v-alert v-if="!welcomeMarketingBannerClosed && !isAuthenticated" tile dismissible class="mb-0 py-2 body-2 grey lighten-3">
        <template #close>
          <v-icon @click="$store.commit('setSettings', { welcomeMarketingBannerClosed: true })">
            mdi-close
          </v-icon>
        </template>
        <div class="d-flex justify-center my-3">
          <span style="max-width: 600px;" v-html="$t('marketing.notice', { marketingUrl })" />
        </div>
      </v-alert>
      <v-alert v-if="isAuthenticated && $feature('additionalAgreement') && presentedAgreements.length > 0" tile class="mb-0 py-2 body-2 grey lighten-3">
        <div class="d-flex justify-center my-3">
          <span style="max-width: 600px;" @click.prevent.stop="$store.commit('setShowAgreementDialog', { showAgreementDialog: true, currentAgreement: first(presentedAgreements) })" v-html="$t('additional-agreement.notice')" />
        </div>
      </v-alert>
      <div style="height:2px;">
        <v-progress-linear v-show="busy || httpLoading || $store.state.facebook.loading" indeterminate color="yellow darken-2" />
      </div>
      <router-view :key="behalfOfRand" />
    </v-main>

    <v-divider :style="{ zIndex: 5 }" />

    <v-footer color="white" :style="{ position: 'relative' }" padless clipped-right app>
      <v-container id="dropdown" :class="{ 'd-flex': true }">
        <v-menu
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="footerMenu"
          close-on-click
          close-on-content-click
          offset-y
          top
          left
        >
          <template #activator="{ on }">
            <v-btn text rounded small class="pl-2" v-on="on">
              <v-icon small class="mr-1">
                {{ footerMenu ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
              </v-icon>
              {{ config.applicationName }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in footerItems"
              :key="item.text"
              :to="item.to"
              :href="item.href"
              :target="item.target"
              @click="item.click != null ? item.click() : () => {}"
            >
              <v-list-item-title class="font-weight-regular">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-for="item in footerItems"
          v-else
          :key="item.text"
          text
          :to="item.to"
          :href="item.href"
          :target="item.target"
          small
          @click="item.click != null ? item.click() : () => {}"
        >
          {{ item.text }}
        </v-btn>
        <div class="flex-grow-1" />
        <v-menu
          v-model="languageMenu"
          close-on-click
          close-on-content-click
          offset-y
          top
          left
        >
          <template #activator="{ on }">
            <v-btn text rounded small class="pr-1" v-on="on">
              {{ $t(`language.${language}`) }}
              <v-flag :size="22" class="ml-2" :lang="language" avatar square />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="language in languages" :key="language" @click="$store.dispatch('setLanguage', { language })">
              <v-list-item-title>{{ $t(`language.${language}`) }}</v-list-item-title>
              <v-list-item-action><v-flag :size="12" class="ml-2" :lang="language" /></v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-footer>

    <v-dialog v-model="updateAvailable" max-width="650px">
      <confirm-box type="update-available">
        <v-spacer />
        <v-btn depressed @click="updateAvailable = false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn depressed color="primary" @click="update">
          {{ $t('button.update') }}
        </v-btn>
      </confirm-box>
    </v-dialog>

    <v-dialog :value="error != null" max-width="600px">
      <error-box :error="error" @close="$store.commit('error', null)" />
    </v-dialog>

    <v-dialog v-if="$feature('behalfOf') && $hasPermission([], ['ADMIN'])" v-model="behalfOfDialog" max-width="650px">
      <behalf-of @close="behalfOfDialog = false" />
    </v-dialog>

    <v-dialog v-if="$feature('additionalAgreement')" v-model="showAgreementDialog" persistent max-width="650px">
      <additional-agreement />
    </v-dialog>

    <notification />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Base from './mixins/base'
import VFlag from './components/VFlag'
import BehalfOf from './components/BehalfOf.vue'
import AdditionalAgreement from '@/components/AdditionalAgreement.vue'
import ConfirmBox from './components/ConfirmBox.vue'
import ErrorBox from './components/ErrorBox.vue'
import NotAvailable from './components/NotAvailable.vue'
import Notification from './components/Notification.vue'
import Loading from './components/Loading.vue'
import qs from 'qs'
import BoAccessRights from '@/utils/bo-access-rights'
import { first } from 'lodash-es'

export default {
  name: 'App',
  components: {
    AdditionalAgreement,
    VFlag,
    BehalfOf,
    ConfirmBox,
    ErrorBox,
    NotAvailable,
    Notification,
    Loading
  },
  mixins: [Base],
  props: {},
  data: () => ({
    footerMenu: false,
    errorDialog: false,
    behalfOfDialog: false,
    additionalAgreementsDialog: false,
    drawer: false,
    accountMenu: false,
    languageMenu: false,
    navbar: {
      color: 'brand',
      dark: true
    },
    behalfOfRand: 1
  }),
  computed: {
    hasBoxOfficeActivation () {
      const { $store: { state: { config : { boxOfficeActivation } } } } = this
      return boxOfficeActivation
    },
    hasSalesAccess () {
      const { $feature, $affiliateFeature, currentAffiliate, behalfOf } = this
      return $affiliateFeature('sales') && (!$feature('viewSepa') || currentAffiliate.sepaMandateAcceptedDate != null) && !behalfOf
    },
    marketingUrl () {
      const { language, config: { marketing: { url }, languages }, $store: { state: { consentManager : { exportConsent } } } } = this
      const cmpExport = exportConsent ? `#cmpimport=${exportConsent}` : ''
      return first(languages) !== language ? `${url}/${language}${cmpExport}` : `${url}${cmpExport}`
    },
    userItems () {
      const { config: { basePath }, currentAffiliate, isAffiliateActive, $feature, $affiliateFeature, $hasPermission, $store: { state: { auth: { token } } }, hasSalesAccess, hasBoxOfficeActivation } = this
      return [
        { text: this.$t('button.dashboard'), to: '/dashboard', if: $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.EVENT_VIEW]) && $feature('dashboard') },
        { text: this.$t('button.events'), to: '/events', if: $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.EVENT_VIEW]) },
        { text: this.$t('button.series'), to: '/series', if: $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.SERIES_VIEW]) },
        { text: this.$t('button.bundles'), to: '/bundles', if: $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.EVENT_VIEW]) },
        { text: this.$t('button.reports'), to: '/reports', if: $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', 'AFFILIATE_REPORT_VIEW']) },
        { text: this.$t('button.access'), to: '/access' },
        { text: this.$t('button.shop'), to: '/shop-config' },
        { text: this.$t('button.promotions'), to: '/promotions', if: !$affiliateFeature('noPromotions') && $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.PROMOTIONS]) && isAffiliateActive },
        { text: this.$t('button.sales-client'), to: hasSalesAccess ? null : '/box-office', href: hasSalesAccess ? `${basePath}/s/?auth=${token}`: null, target: hasSalesAccess ? '_blank' : null, if: $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN', 'SALES_CLIENT']) && currentAffiliate != null && (hasBoxOfficeActivation || hasSalesAccess) }
      ].filter(item => item.if === undefined ? true : item.if)
    },
    guestItems () {
      return [
        { text: this.$t('button.login'), to: '/login' },
        { text: this.$t('button.register'), to: '/register', if: this.$feature('register') }
      ].filter(item => item.if === undefined ? true : item.if)
    },
    accountItems () {
      const { config: { marketing: { help } }, $hasPermission, $feature, showDrawer, additionalAgreements } = this
      return [
        { text: this.$t('button.my-profile'), to: '/user/settings' },
        { text: this.$t('button.behalf-of'), click: () => (this.behalfOfDialog = true), if: $feature('behalfOf') && $hasPermission([], ['ADMIN']) },
        { text: this.$t('button.collective-settlement'), to: '/affiliate/collective-settlement', if: $feature('collectiveSettlements') && $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN']) },
        { text: this.$t('button.additional-agreements'), to: '/affiliate/additional-agreements', if: $feature('additionalAgreement') && additionalAgreements.length > 0 && $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN']) },
        { text: this.$t('button.help'), to: '/help', if: $feature('hasMarketing') && help && showDrawer && $hasPermission([], ['ADMIN', 'AFFILIATE_ADMIN']) },
        { text: this.$t('button.logout'), to: '/logout' }
      ].filter(item => item.if === undefined ? true : item.if)
    },
    footerItems () {
      const { config: { basePath, links: { compliance } }, language, complianceLink } = this
      return [
        { text: this.$t('button.gtc'), to: '/terms-and-conditions', if: this.$feature('viewTerms') },
        { text: this.$t('button.legal-notice'), to: '/legal-notice', if: this.$feature('viewLegalNotice') },
        { text: this.$t('button.imprint'), to: '/imprint', if: this.$feature('viewImprint') },
        { text: this.$t('button.privacy-policy'), to: '/privacy-policy', if: this.$feature('viewPrivacy') },
        { text: this.$t('button.compliance'), href: complianceLink, if: compliance != null },
        { text: this.$t('button.faq'), href: `${basePath}/faq?lang=${language}`, if: this.$feature('hasMarketing') },
        { text: this.$t('button.contact'), to: '/contact' },
      ].filter(item => item.if === undefined ? true : item.if)
    },
    showDrawer () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    updateAvailable: {
      set () {
        return this.$store.commit('update/skip')
      },
      get () {
        return this.$store.getters['update/available']
      }
    },
    routePath () {
      return this.$route.path
    },
    complianceLink () {
      const { config: { links: { compliance } }, language } = this
      if (!compliance) return
      return compliance[language] ? compliance[language] : compliance.default
    },
    ...mapState({
      ready: ({ ready }) => ready,
      unavailable: ({ unavailable }) => unavailable,
      removeVersion: ({ update }) => update.version,
      busy: ({ auth: { busy: auth }, languages: { busy: languages } }) => auth || languages,
      languages: ({ config: { languages } }) => languages,
      language: (_, { language }) => language,
      error: ({ error }) => error,
      welcomeMarketingBannerClosed: ({ settings }) => settings.welcomeMarketingBannerClosed,
      presentedAgreements: (_, { presentedAgreements }) => presentedAgreements,
      additionalAgreements: (_, { additionalAgreements }) => additionalAgreements,
      showAgreementDialog: (_, { showAgreementDialog }) => showAgreementDialog
    })
  },
  watch: {
    showDrawer (next) {
      if (next) this.drawer = false
    },
    language (next) {
      this.$vuetify.lang.current = next
    },
    routePath () {
      this.startBehalfOf()
      this.postMessage()
    },
    behalfOf () {
      this.behalfOfRand = Math.round(Math.random() * 1000)
      this.postMessage()
    },
    currentAffiliateId () {
      this.$store.dispatch('GTM_PUSH', { affiliateId: this.currentAffiliateId })
      this.$store.dispatch('GTM_PUSH_LOGIN')
      if (this.$feature('additionalAgreement')) this.$store.dispatch('findAdditionalAgreements')
    }
  },
  errorCaptured (error) {
    this.$store.commit('error', error)
  },
  mounted () {
    this.$vuetify.lang.current = this.language
    this.$store.dispatch('GTM_PUSH_LOGIN')
    if (this.$feature('additionalAgreement')) this.$store.dispatch('findAdditionalAgreements')
  },
  methods: {
    first,
    update () {
      const query = window.location.search ? qs.parse(window.location.search.substr(1)) : {}
      query._v = this.removeVersion
      window.location = window.location.href.split('?')[0] + '?' + qs.stringify(query)
    },
    stopBehalfOf () {
      this.$store.commit('behalfOf', null)
      this.$router.push('/')
    },
    async startBehalfOf () {
      const { $route: { query: { affiliateId } = {} }, isAuthenticated, $hasPermission } = this
      if ($hasPermission([], ['ADMIN', 'ACCOUNTANT', 'SALES', 'SUPPORT', 'VENUE', 'SUPPORT CONSUMER', 'SUPPORT OUTLETS']) && this.$feature('behalfOf') && isAuthenticated && affiliateId != null) {
        await this.$store.dispatch('behalfOf', { affiliateId })
        this.behalfOfRand = Math.round(Math.random() * 1000)
      }
    },
    postMessage () {
      const { $route: { params, path }, behalfOf, currentAffiliateId } = this
      window.parent.postMessage({ path, ...params, behalfOfId: behalfOf ? currentAffiliateId : null, context: 'behalfOf' }, '*')
    }
  },
  metaInfo () {
    const { $store: { state: { config: { meta: cmeta = [], applicationName } }, getters: { language } } } = this
    const title = this.$t('meta.title', { applicationName })
    const description = this.$t('meta.description', { applicationName })
    const meta = [
      { vmid: 'description', name: 'description', content: description },
      ...cmeta
    ]
    return {
      htmlAttrs: {
        lang: language
      },
      titleTemplate: (titleChunk) => titleChunk ? `${titleChunk} | ${title}` : title,
      meta
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.v-btn, .v-tab {
  text-transform: none !important;
}
.v-application .grey.lighten-3.v-app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
}
.v-toolbar .title {
  white-space: nowrap;
}
.v-footer {
  overflow-x: auto;
  overflow-y: hidden;
}

.theme--light.v-application .v-content {
  background-color: #fff;
}

a:not(.v-btn):not(.v-tab):not(.v-list-item) {
  font-weight: bold;
  text-decoration: none;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rotate-180 {
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

#app .lineheight-normal {
  line-height: normal;
}

#app .input-height {
  height: 56px;
}

#app .v-snack--top {
  top: 46px;
}

#app .v-card__title {
  word-break: normal;
}

#app .v-data-footer {
  white-space: nowrap;
  overflow: hidden;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

#app .bc-input {
  border-color: rgba(0, 0, 0, 0.38) !important;
  transition-duration: 0.3s;
  transition-property: border-color;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover, .bc-input-active {
    border-color: rgba(0, 0, 0, 1) !important;
    .bc-input {
      border-color: rgba(0, 0, 0, 1) !important;
    }
  }
  &.has-error {
    border: 2px solid var(--v-error-base) !important;
    border-color: var(--v-error-base) !important;
    .bc-input {
      border-color: var(--v-error-base) !important;
    }
  }
}

#app .v-input.v-input--is-disabled.v-text-field .v-input__control .v-input__slot {
  background-color: #ececec;
}

#app .v-tabs {
  border-bottom: 1px solid var(--v-primary-base)
}

#app .v-data-table-header th {
  white-space: nowrap;
}
</style>
