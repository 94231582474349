import axios from 'axios'
import qs from 'qs'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
    return {
      list: [],
      showNewsBanner: true
    }
  },
  mutations: {
    newsBanners: (state, { items }) => {
      state.list.splice(0, state.list.length, ...(items || []))
    },
    setShowNewsBanner: (state, data) => {
      Object.assign(state, data)
    }
  },
  getters: {
    newsBanner: ({ list }) => list.find(b => b != null),
    banners: ({ list }) => list.filter(b => b != null).slice(0, 5),
    // todo may add condition
    showNewsBanner: ({showNewsBanner}) => showNewsBanner
  },
  actions: {
    findNewsBanners: async ({ commit, getters: { affiliateId } }) => {
      const query = qs.stringify({}, { skipNulls: true })
      return axios.get(`${apiPath}/affiliate/${affiliateId}/newsbanner?${query}`)
        .then(({ data: items }) => commit('newsBanners', { items }))
        .catch((error) => {
          throw error
        })
    }
  }
}
